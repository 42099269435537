import React from "react";

import {QueryStatus} from "../../../../hooks/useQueryController";
import {useReadableSlotTime} from "../../../../hooks/useReadableSlotTime";
import {CareDiscoveryRowClickReporter} from "../../Reason/CareDiscoverySearch/careDiscoveryAnalytics";
import {useTopicStack} from "../../Reason/CareDiscoverySearch/useTopicStack";
import CareEntryRow from "../Presentation";
import {CareEntryRowVariant, ExtendedCareEntrySearchResult} from "..";
import {ReasonForCareEntryIcon, useExtendCareEntry} from "../../../../hooks/useCareEntryExtensions";
import {useOnClick, useSoonestSlot} from "@components/CareEntryRow/Control/hooks";
import {LocationForSoonestSlotForSlug} from "../../../../queries/useSoonestSlotByReasonSlug";

export type SelectedLocation = {
  address: {
    state: string;
  };
  id: string;
  practiceId: string;
  region: {name: string; slug: string};
  slug: string;
  specialtyIds: string[];
};

type Props = {
  result: ExtendedCareEntrySearchResult;
  rank: number;
  reasons: ReasonForCareEntryIcon[];
  locations: LocationForSoonestSlotForSlug[];
  query?: string;
  variant?: CareEntryRowVariant;
  selectedLocation?: SelectedLocation;
  isLoading?: boolean;
  reportClickEvent?: CareDiscoveryRowClickReporter;
  cypressKey: string;
  maybeToggleDialog?: (shouldRedirect: boolean) => void;
  maybeAddCareToTopicStack?: ReturnType<typeof useTopicStack>["maybeAddToStack"];
  /**
   * Prevents row clicks from firing `AppointmentBookingStarted` events after flow has already started.
   */
  canSendApptStartEvent?: boolean;
  viewId?: string;
};

const CareEntryRowControl = ({
  result,
  reasons,
  locations,
  selectedLocation,
  query,
  rank,
  variant = CareEntryRowVariant.MD,
  isLoading = false,
  reportClickEvent,
  maybeToggleDialog,
  maybeAddCareToTopicStack,
  cypressKey,
  canSendApptStartEvent = false,
  viewId,
}: Props) => {
  const extendedCareEntry = useExtendCareEntry(result, reasons);
  const {
    name,
    brief_description,
    turnaround,
    price,
    icon = "defaultReasonIcon",
  } = extendedCareEntry;
  const soonestSlotQuery = useSoonestSlot(extendedCareEntry, locations, selectedLocation);
  const readableSlotTime = useReadableSlotTime(soonestSlotQuery?.data?.time, {
    withNext: true,
  });
  const onClick = useOnClick({
    canSendApptStartEvent,
    extendedCareEntry,
    maybeAddCareToTopicStack,
    maybeToggleDialog,
    rank,
    reportClickEvent,
    soonestSlot: soonestSlotQuery?.data,
    selectedLocation,
    viewId,
  });

  return (
    <CareEntryRow
      cypressKey={cypressKey}
      query={query}
      onClick={onClick}
      icon={icon}
      line1={name}
      line2={brief_description || undefined}
      price={price}
      turnaround={turnaround}
      isSlotLoading={soonestSlotQuery.status === QueryStatus.LOADING}
      readableSlotTime={readableSlotTime || undefined}
      variant={variant}
      isLoading={isLoading}
    />
  );
};
CareEntryRowControl.displayName = "CareEntryRowControl";
export default React.memo(CareEntryRowControl);
