import {useMemo} from "react";

import {
  LocationForSoonestSlotForSlug,
  useSoonestSlotByReasonSlugsQuery,
} from "../../../queries/useSoonestSlotByReasonSlug";
import {ExtendedCareEntrySearchResult} from ".";

export const useSoonestSlotForResult = (
  care: ExtendedCareEntrySearchResult,
  locations: LocationForSoonestSlotForSlug[],
  selectedLocation?: {
    id: string;
    slug: string;
    region: {name: string; slug: string};
    specialtyIds: string[];
  },
  selectedRegion?: string,
) => {
  const isTopic = !care.appointment_reason;
  const slugsToCheck = useMemo(
    () => (isTopic ? care.cares?.map(care => care.slug).slice(0, 4) || [] : [care.slug]),
    [isTopic, care.cares, care.slug],
  );

  return useSoonestSlotByReasonSlugsQuery(
    slugsToCheck,
    locations,
    selectedLocation,
    selectedRegion,
  );
};
