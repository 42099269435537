import {useCallback} from "react";
import {getSchedulingLink} from "@page-containers/booking/utils/bookingQuery";
import {sendInitiateWebSchedulingEvent} from "@components/Locations/events";
import {v5Pages} from "../../../_common/_constants";
import {ExtendedCareEntrySearchResult} from "@components/CareEntryRow/index";
import {useSoonestSlotForResult} from "@components/CareEntryRow/useSoonestSlotForResult";
import {hasRelatedCares} from "@components/Reason/CareDiscoverySearch/getAllRelatedCares";
import {SelectedLocation} from "@components/CareEntryRow/Control";
import {ApptSlot} from "@services/monolith/availability";
import {useTopicStack} from "@components/Reason/CareDiscoverySearch/useTopicStack";
import {CareDiscoveryRowClickReporter} from "@components/Reason/CareDiscoverySearch/careDiscoveryAnalytics";
import {LocationForSoonestSlotForSlug} from "../../../../queries/useSoonestSlotByReasonSlug";
import useNextPagesPaths from "../../../../hooks/app-pages-transition/useNextPagesPaths";
import {createURLBuilder} from "../../../../utils/urls";
import {useUserSelectedLocation} from "../../../../utils/browser-storage/userSelectedLocation";
import {useRouter} from "next/navigation";

export function useSoonestSlot(
  extendedCareEntry: ExtendedCareEntrySearchResult,
  locations: LocationForSoonestSlotForSlug[],
  selectedLocation?: SelectedLocation,
) {
  const {regionSlug} = useUserSelectedLocation();
  return useSoonestSlotForResult(extendedCareEntry, locations, selectedLocation, regionSlug);
}

export function useOnClick({
  canSendApptStartEvent,
  extendedCareEntry,
  maybeAddCareToTopicStack,
  maybeToggleDialog,
  rank,
  reportClickEvent,
  soonestSlot,
  selectedLocation,
  viewId,
}: {
  canSendApptStartEvent?: boolean;
  extendedCareEntry: ExtendedCareEntrySearchResult;
  maybeAddCareToTopicStack?: ReturnType<typeof useTopicStack>["maybeAddToStack"];
  maybeToggleDialog?: (shouldRedirect: boolean) => void;
  rank: number;
  reportClickEvent?: CareDiscoveryRowClickReporter;
  soonestSlot?: ApptSlot | null;
  selectedLocation?: SelectedLocation;
  viewId?: string;
}) {
  const {asPath} = useNextPagesPaths();
  const {push} = useRouter();
  const isTopic = !extendedCareEntry.appointment_reason;
  const {slug, override_url} = extendedCareEntry;

  const maybeAddSelfToStack = maybeAddCareToTopicStack?.(
    extendedCareEntry.slug,
    hasRelatedCares(extendedCareEntry),
  );

  return useCallback(() => {
    const shouldStartBooking = !isTopic && selectedLocation && soonestSlot?.time;
    if (maybeAddSelfToStack) {
      maybeAddSelfToStack(extendedCareEntry);
      reportClickEvent?.(extendedCareEntry, soonestSlot, rank, undefined, viewId);
    } else if (shouldStartBooking) {
      const specialtyId = soonestSlot?.specialtyIds?.[0];
      const {bookingLink, flowId, WEB_SCHEDULING_ENABLED} = getSchedulingLink({
        specialtyIds: soonestSlot?.specialtyIds || [],
        webSchedulingQuery: {
          locationSlug: selectedLocation?.slug,
          appointmentReasonSlug: extendedCareEntry.slug,
          originUrl: asPath,
          practiceId: selectedLocation?.practiceId,
          initialCalendarTime: soonestSlot?.time,
          specialtyId,
        },
        patientAppSchedulingQuery: {
          practiceId: selectedLocation?.practiceId,
          specialtyId,
          apptReasonId: extendedCareEntry.appointment_reason || undefined,
          locationId: selectedLocation?.id,
          preselectedState: selectedLocation?.address.state,
        },
      });

      if (WEB_SCHEDULING_ENABLED && canSendApptStartEvent) {
        sendInitiateWebSchedulingEvent(flowId);
      }

      reportClickEvent?.(extendedCareEntry, soonestSlot, rank, flowId, viewId);

      push(bookingLink);
    } else {
      reportClickEvent?.(extendedCareEntry, soonestSlot, rank, undefined, viewId);
      const carePageHref = createURLBuilder(v5Pages.getCareSlug).replaceSegment(slug).build();
      const href = override_url || carePageHref;
      if (href) {
        push(href);
      }
    }

    maybeToggleDialog?.(!maybeAddSelfToStack);
  }, [
    isTopic,
    selectedLocation,
    soonestSlot,
    maybeAddSelfToStack,
    maybeToggleDialog,
    extendedCareEntry,
    reportClickEvent,
    rank,
    viewId,
    asPath,
    canSendApptStartEvent,
    push,
    slug,
    override_url,
  ]);
}
