import * as Sentry from "@sentry/nextjs";
import {api} from "@services/api";
import {CareEntrySearchResult, RawCareEntrySearchResult} from "@services/monolith/careEntries";
import {Hit, SearchResponse} from "../types";
import {
  FormattedCareEntryResponse,
  applyOverrides,
  fetchFields,
  formatCareEntryResponse,
} from "../cms";
import {fetchCareEntry as fetchRawCareEntry} from "../../../_services/monolith/careEntries/client";
import memoizee from "memoizee";
import {MsMap} from "@constants/MsMap";

export const fetchCareEntries = (
  query: string,
  options?: {limit?: number; offset?: number},
): Promise<CareEntrySearchResult[]> =>
  api
    .post("/hib/care/search", {
      must: [
        {
          text: query,
          type: "TYPE_CUMULATIVE",
          fuzzy: true,
        },
      ],
      should: [
        {
          text: query,
          type: "TYPE_STANDARD",
          fuzzy: false,
        },
      ],
      limit: options?.limit || 40,
      offset: options?.offset ?? 0,
      fetch_fields: fetchFields,
    })
    .then(res => res.json())
    .then((json: SearchResponse<Hit<RawCareEntrySearchResult>>) =>
      json.hits.map(result => result.document),
    )
    .then(results => results.map(applyOverrides));

export const fetchCareEntry = (slug: string): Promise<FormattedCareEntryResponse | null> =>
  fetchRawCareEntry(slug)
    .then(formatCareEntryResponse)
    .catch(err => {
      Sentry.captureException(err, scope => {
        scope.setContext("Context", {
          slug,
        });
        return scope;
      });
      return null;
    });

export const memoizedFetchCareEntry = memoizee(fetchCareEntry, {
  promise: true,
  maxAge: MsMap.ONE_MINUTE,
});
