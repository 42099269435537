import {
  Care,
  CareTopic,
  CareTopicRaw,
  FetchCareEntryResponse,
  RawCareEntrySearchResult,
  RelatedCare,
  RelatedCareOverrideWrapper,
  RelatedTopic,
  RelatedTopicOverrideWrapper,
} from "@services/monolith/careEntries";

export const fetchFields: (keyof RawCareEntrySearchResult)[] = [
  "name",
  "appointment_reason",
  "description",
  "brief_description",
  "slug",
  "cares",
  "related_topics",
];

export const applyCareOverride = ({
  care = {} as RelatedCare,
  ...overrides
}: RelatedCareOverrideWrapper): RelatedCare => ({...care, ...overrides.pickBy()});

export const applyRelatedTopicOverrides = ({
  topic = {} as RelatedTopic,
  ...overrides
}: // @ts-expect-error TS2322: Type '{ brief_description: string | null; name: string | null; description: string | null; insurance_type: CareInsuranceType; locale: string; rank_boost: number; slug: string; }' is not assignable to type 'RelatedTopic'.
RelatedTopicOverrideWrapper): RelatedTopic => ({...topic, ...overrides.pickBy()});

/**
 * If an entry has a related care that has been unpublished, it will not have a slug.
 * This filters out such entries.
 */
const isValidCare = (care: RelatedCare) => Boolean(care.slug);

export const applyOverrides = (result: RawCareEntrySearchResult) => ({
  ...result,
  cares: result.cares?.map(applyCareOverride).filter(isValidCare) || [],
  related_topics: result.related_topics?.map(applyRelatedTopicOverrides) || [],
});

export const isCareReason = (content: FetchCareEntryResponse): content is Care =>
  // @ts-expect-error TS2322: Type 'boolean | null' is not assignable to type 'boolean'.
  content && "appointment_reason" in content;

export const isCareTopic = (content: FetchCareEntryResponse): content is CareTopicRaw =>
  // @ts-expect-error TS2322: Type 'boolean | null' is not assignable to type 'boolean'.
  content && "related_topics" in content;

export enum CareType {
  TOPIC = "TOPIC",
  REASON = "REASON",
}

export type FormattedCareEntryResponse =
  | {type: CareType.TOPIC; content: CareTopic}
  | {type: CareType.REASON; content: Care}
  | null;

/**
 * This API returns both appt reason content and topic content.
 * This utility determines the type of the response
 * and applies override values for related records.
 * @param entry API response for '/hib/care/[slug]
 */
export const formatCareEntryResponse = (
  entry: FetchCareEntryResponse,
): FormattedCareEntryResponse => {
  if (isCareReason(entry))
    return {
      type: CareType.REASON,
      content: entry as Care,
    };

  if (isCareTopic(entry))
    return {
      type: CareType.TOPIC,
      content: applyOverrides(entry) as CareTopic,
    };

  return null;
};
