import memoizee from "memoizee";

import {RelatedCare, RelatedTopic} from "@services/monolith/careEntries";

export const getAllRelatedCares = memoizee(
  ({
    cares = [],
    related_topics = [],
  }: {
    cares?: RelatedCare[] | null;
    related_topics?: RelatedTopic[] | null;
  }): (RelatedCare | RelatedTopic)[] => [...(cares || []), ...(related_topics || [])],
);

export const hasRelatedCares = memoizee(
  ({
    cares,
    related_topics,
  }: {
    cares?: RelatedCare[] | null;
    related_topics?: RelatedTopic[] | null;
  }) => {
    return !!(cares?.length || related_topics?.length);
  },
);
